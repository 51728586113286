import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Alert,
    UncontrolledTooltip
} from "reactstrap";
//   import { Link } from "react-router-dom";
import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { MDBDataTable } from 'mdbreact';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { RotatingLines } from  'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';

// Import datatable css
import "../Tables/datatables.scss";


//url
import url from "../../helpers/apiUrl"
import Moment from "moment"

class CustomerService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "One Dash", link: "dashboard" },
                { title: "Customer Support", link: "#" },
            ],
            tickets: [],
            created_at: '',
            upated_at: '',
            ticket_name: '',
            ticket_description: '',
            prev_ticket_description: '',
            ticket_assignment: '',
            ticket_status: '',
            prev_ticket_status: '',
            priority_name: '',
            category_name: '',
            todayDate: new Date(),
            category_id: '',
            priority_id: '',
            prev_priority_id: '',
            category: [],
            priority: [],
            technician: [],
            id: '',
            completeAlert: false,
            success_confirm: false,
            alert_confirm: false,
            dynamic_title: "",
            dynamic_description: "",
            modal_technician: false,
            modal_create_ticket: false,
            modal_edit_ticket: false,
            modal_add: false,
            customer_remarks: '',
            technician_remarks: '',
            complition_date: '',
            prev_customer_remarks: '',
            prev_technician_remarks: '',
            prev_complition_date: '',
            technician_id: '',
            technician_name: '',
            support_log_id: '',
            openCount: '',
            newCount: '',
            runningCount: '',
            completedCount: '',
            user_id: '',
            users: [],
            category_id_filter: '',
            priority_id_filter: '',
            user_id_filter: '',
            ticket_id_filter: '',
            ticket_status_filter: '',
            technician_id_filter: '',
            from_date_filter: '',
            to_date_filter: '',
            userAlert: false,
            priorityAlert: false,
            categoryAlert: false,
            assignmentAlert: false,
            statusAlert: false,
            userUpdateAlert: false,
            updateFailed: false,
            updateFailedMasg: '',
            prev_ticket_assignment: '',
            created_at_filter: '',
            fromDate: '',
            toDate: '',
            creatTicket: false,
            DateAlert:false,
            isLoadingSave: false,
        }
        this.tog_technician = this.tog_technician.bind(this);
        this.tog_create_ticket = this.tog_create_ticket.bind(this);
        this.createSupportLog = this.createSupportLog.bind(this);
        this.vieallSupportPriority = this.vieallSupportPriority.bind(this);
        this.viewsupportCategory = this.viewsupportCategory.bind(this);
        this.deleteSupportLog = this.deleteSupportLog.bind(this);
        this.viewSupportLog = this.viewSupportLog.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.updateSupportLog = this.updateSupportLog.bind(this);
        this.tog_edit_ticket = this.tog_edit_ticket.bind(this);
        this.fillTicket = this.fillTicket.bind(this);
        this.assignTechnician = this.assignTechnician.bind(this);
        this.getTechnician = this.getTechnician.bind(this);
        this.fillTechnician = this.fillTechnician.bind(this);
        this.supportCount = this.supportCount.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.handleDatechange = this.handleDatechange.bind(this);
        this.handleToDatechange = this.handleToDatechange.bind(this);
        this.tog_add = this.tog_add.bind(this);
        this.tog_add2 = this.tog_add2.bind(this);
        this.disableToast = this.disableToast.bind(this);

    }

    componentDidMount() {
        if (localStorage.getItem("token") === null) {
            return (
                this.props.history.push('/login')
                // <Redirect to={{ pathname: "/login" }} exact />
            );
        }
        this.supportCount()
        this.props.setBreadcrumbItems("Customer Service", this.state.breadcrumbItems);
        this.vieallSupportPriority();
        this.viewsupportCategory();
        this.getTechnician()
        this.getUsers()

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var raw = JSON.stringify({
            "category": this.state.category_id_filter,
            "priority_id": this.state.priority_id_filter,
            "user_id": this.state.user_id_filter,
            "ticket_id": this.state.ticket_id_filter,
            "ticket_status": this.state.ticket_status_filter,
            "ticket_assignment": this.state.technician_id_filter,
            "from_date": this.state.from_date_filter,
            "to_date": this.state.to_date_filter
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/support/log`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                var array = []
                if (data.statusCode === "200") {
                    console.log(data, "dataaa")
                    for (let i = 0; i < data.body.length; i++) {
                        console.log("date", Moment(data.body[0].complition_date).format('YYYY/MM/DD HH:mm:ss') === 'Invalid date')
                        array.push({
                            support_log_id: data.body[i].id,
                            ticket_id: data.body[i].ticket_id,
                            ticket_name: data.body[i].ticket_name,
                            ticket_description: data.body[i].ticket_description,
                            user_id: data.body[i].user_id,
                            ticket_status: data.body[i].ticket_status,
                            priority_name: data.body[i].priority_name,
                            category_name: data.body[i].category_name === "null" ? "-" : data.body[i].category_name ,
                            technician_name: data.body[i].first_name + " " + data.body[i].last_name == "null null" ? "-" : data.body[i].first_name + " " + data.body[i].last_name,
                            customer_remarks: data.body[i].customer_remarks === "" ? "-" : data.body[i].customer_remarks ,
                            technician_remarks: data.body[i].technician_remarks === "" ? "-" : data.body[i].technician_remarks,
                            complition_date: data.body[i].complition_date == "0000-00-00 00:00:00" ? "-" :  Moment(data.body[i].complition_date).format('YYYY/MM/DD'),
                            ticket_assignment: data.body[i].ticket_assignment,
                            created_at: Moment(data.body[i].created_at).format('YYYY/MM/DD HH:mm:ss'),
                            updated_at: Moment(data.body[i].updated_at).format('YYYY/MM/DD HH:mm:ss'),
                            button:
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillTicket(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10, marginBottom: 10 }
                                        }
                                        id="tooltip_edit_ticket"
                                        color="primary"
                                        className="waves-effect waves-light buttons-secondary">
                                        <i className="ti-pencil"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip_edit_ticket">
                                        Edit 
                                    </UncontrolledTooltip>
                                    <Button type="button" color="danger"
                                        onClick={
                                            () => this.setState({ ticket_name: data.body[i].ticket_name, alert_confirm: true, id: data.body[i].id })
                                        }
                                        style={{ marginRight: 10, marginBottom: 10 }}
                                        className="waves-effect waves-light buttons-secondary"
                                        id="tooltip_delete_ticket"><i className="ti-trash"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip_delete_ticket">
                                        Delete
                                    </UncontrolledTooltip>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillTechnician(data.body[i].id, data.body[i].ticket_assignment)
                                        }
                                        style={
                                            { marginRight: 10, marginBottom: 10 }
                                        }
                                        id="tooltip_assign_tech_ticket"
                                        color="success"
                                        className="waves-effect waves-light buttons-secondary">
                                        <i className="fas fa-user"></i>
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="tooltip_assign_tech_ticket">
                                        Assign Technician
                                    </UncontrolledTooltip>
                                </div>

                        })
                    }
                    this.setState({ tickets: array })
                }
                else {
                    this.setState({
                        tickets: []
                    })
                }
            })
            .catch(error => console.log('error', error));

        this.supportCount()
    }

    viewSupportLog() {
        this.setState({isLoadingSave : true})

        var date = this.state.created_at_filter;
        var fromDate = ''
        var toDate = ''
        if (date === "custom2") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }

        
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "category": this.state.category_id_filter,
                "priority_id": this.state.priority_id_filter,
                "user_id": this.state.user_id_filter,
                "ticket_id": this.state.ticket_id_filter,
                "ticket_status": this.state.ticket_status_filter,
                "ticket_assignment": this.state.technician_id_filter,
                "from_date": fromDate,
                "to_date": toDate
            });
            console.log("Filter raws", raw)

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${url}/support/log`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error === "Invalid Token"){
                    localStorage.removeItem('token');
                    sessionStorage.clear();
                    this.props.history.push('/login') 
                }
                console.log("dataaa", data)
                var array = []
                if (data.statusCode === "200") {
                    this.setState({isLoadingSave : false})

                   
                        if(this.state.modal_add === true){
                            this.setState(prevState => ({
                                modal_add: false
                            }));
                        } 
                    
                    for (let i = 0; i < data.body.length; i++) {
                        console.log(typeof data.body[i].complition_date, "type of co da")
                        array.push({
                            support_log_id: data.body[i].id,
                            ticket_id: data.body[i].ticket_id,
                            ticket_name: data.body[i].ticket_name,
                            ticket_description: data.body[i].ticket_description,
                            user_id: data.body[i].user_id,
                            ticket_status: data.body[i].ticket_status,
                            priority_name: data.body[i].priority_name,
                            category_name: data.body[i].category_name,
                            technician_name: data.body[i].first_name + " " + data.body[i].last_name === "null null" ? "-" : data.body[i].first_name + " " + data.body[i].last_name ,
                            customer_remarks: data.body[i].customer_remarks === "" ? "-" : data.body[i].customer_remarks ,
                            technician_remarks: data.body[i].technician_remarks === "" ? "-" : data.body[i].technician_remarks,
                            complition_date: data.body[i].complition_date == "0000-00-00 00:00:00" ? "-" :  Moment(data.body[i].complition_date).format('YYYY/MM/DD'),
                            ticket_assignment: data.body[i].ticket_assignment,
                            created_at: Moment(data.body[i].created_at).format('YYYY/MM/DD HH:mm:ss'),
                            updated_at: Moment(data.body[i].updated_at).format('YYYY/MM/DD HH:mm:ss'),
                            button:
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillTicket(data.body[i])
                                        }
                                        style={
                                            { marginRight: 10, marginBottom: 10 }
                                        }
                                        color="primary"
                                        className="waves-effect waves-light buttons-secondary">
                                        <i className="ti-pencil"></i>
                                    </Button>
                                    <Button type="button" color="danger"
                                        onClick={
                                            () => this.setState({ ticket_name: data.body[i].ticket_name, alert_confirm: true, id: data.body[i].id })
                                        }
                                        style={{ marginRight: 10, marginBottom: 10 }}
                                        className="waves-effect waves-light buttons-secondary"
                                        id="sa-warning"><i className="ti-trash"></i>
                                    </Button>
                                    <Button type="button"
                                        onClick={
                                            () => this.fillTechnician(data.body[i].id, data.body[i].ticket_assignment)
                                        }
                                        style={
                                            { marginRight: 10, marginBottom: 10 }
                                        }
                                        color="success"
                                        className="waves-effect waves-light buttons-secondary">
                                        <i className="fas fa-user"></i>
                                    </Button>
                                </div>

                        })
                    }
                    
                    this.setState({ tickets: array })
                }
                else {
                    this.setState({
                        tickets: []
                    })
                }
            })
            .catch(error => console.log('error', error));
        
    }

    createSupportLog() {
        if (this.state.user_id === "") {
            // this.setState({
            //     userAlert: true
            // })
            // setTimeout(() => {
            //     this.setState({
            //         userAlert: false
            //     })
            // }, 3000);
            toast(<div className='toast-message-container'>
            <h6>Ticket Management</h6>
            <p>Please select a user.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return

        }

        if (this.state.category_id === "") {
            // this.setState({
            //     categoryAlert: true
            // })
            // setTimeout(() => {
            //     this.setState({
            //         categoryAlert: false
            //     })
            // }, 3000);
            toast(<div className='toast-message-container'>
            <h6>Ticket Management</h6>
            <p>Please select a category.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return
        }
        if (this.state.priority_id === "") {
            toast(<div className='toast-message-container'>
            <h6>Ticket Management</h6>
            <p>Please select priority level.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return
        }
        if (this.state.ticket_assignment === "") {
            toast(<div className='toast-message-container'>
            <h6>Ticket Management</h6>
            <p>Please select a technician.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return
        }
        if(this.state.complition_date < Moment().format('YYYY-MM-DD')){
            // this.setState({
            //     completeAlert: true
            // })
            // setTimeout(() => {
            //     this.setState({
            //         completeAlert: false
            //     }) 
            // }, 3000)
            toast(<div className='toast-message-container'>
            <h6>Ticket Management</h6>
            <p>Please select a valid completion date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return 
        }
       
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "ticket_name": this.state.ticket_name,
            "ticket_description": this.state.ticket_description,
            "category_id": this.state.category_id,
            "priority_id": this.state.priority_id,
            "ticket_assignment": this.state.ticket_assignment,
            "ticket_status": "New",
            "customer_remarks": this.state.customer_remarks,
            "technician_remarks": this.state.technician_remarks,
            "complition_date": this.state.complition_date,
            "user_id": this.state.user_id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/support/log/create`, requestOptions)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    ticket_name: '',
                    ticket_description: '',
                    category_id: '',
                    priority_id: '',
                    ticket_assignment: '',
                    customer_remarks: '',
                    technician_remarks: '',
                    complition_date: '',
                    user_id: '',
                    //creatTicket: true
                })

                this.tog_create_ticket()
                setTimeout(() =>{
                    this.viewSupportLog()
                }, 1500)
                
                this.supportCount()
                // setTimeout(() => {
                //     this.setState({
                //         creatTicket: false
                //     })
                // }, 3000);
                toast(<div className='toast-message-container'>
                <h6>Ticket Management</h6>
                <p>Ticket created successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
            })
            .catch(error => console.log('error', error));
    }

    deleteSupportLog() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/support/log/` + this.state.id, requestOptions)
            .then(response => response.json())
            .then(data => {
                
                toast(<div className='toast-message-container'>
                <h6>Ticket Management</h6>
                <p>Ticket deleted successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
                this.viewSupportLog()
            })
            .catch(error => console.log('error', error));
    }

    updateSupportLog() {
        if(this.state.complition_date < Moment().format('YYYY-MM-DD')){
            // this.setState({
            //     completeAlert: true
            // })
            // setTimeout(() => {
            //     this.setState({
            //         completeAlert: false
            //     }) 
            // }, 3000)
            toast(<div className='toast-message-container'>
            <h6>Ticket Management</h6>
            <p>Please select completion date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
            return 
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "ticket_name": this.state.ticket_name,
            "ticket_description": this.state.ticket_description,
            "category_id": this.state.category_id,
            "priority_id": this.state.priority_id,
            "ticket_assignment": this.state.ticket_assignment,
            "ticket_status": this.state.ticket_status,
            "customer_remarks": this.state.customer_remarks,
            "technician_remarks": this.state.technician_remarks,
            "complition_date": this.state.complition_date,
            "user_id": this.state.user_id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/support/log/create/` + this.state.id, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.supportCount()
                if (result.statusCode === "200") {
                    this.tog_edit_ticket()
                    this.viewSupportLog()
                    this.setState({
                        ticket_name: '',
                        ticket_description: '',
                        category_id: '',
                        priority_id: '',
                        ticket_assignment: '',
                        ticket_status: '',
                        customer_remarks: '',
                        technician_remarks: '',
                        complition_date: '',
                        user_id: '',
                        //userUpdateAlert: true
                    })
                    // setTimeout(() => {
                    //     this.setState({
                    //         userUpdateAlert: false
                    //     })
                    // }, 3000);
                    toast(<div className='toast-message-container'>
                    <h6>Ticket Management</h6>
                    <p>Ticket updated successfully.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-success'
                 })
                } else {
                    // this.setState({
                    //     updateFailedMasg: result.body[0].msg,
                    //     updateFailed: true
                    // })
                    // setTimeout(() => {
                    //     this.setState({
                    //         updateFailed: false
                    //     })
                    // }, 3000);
                    toast(<div className='toast-message-container'>
                    <h6>Ticket Management</h6>
                    <p>Ticket could not be updated.</p>
                    </div>, {
                        position: "bottom-right",
                        icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'toast-error'
                 })
                    return;
                }
            })
            .catch(error => console.log('error', error));
    }

    fillTicket = (ticket) => {
        console.log("kin", ticket.complition_date)
        this.setState({
            id: ticket.id,
            ticket_name: ticket.ticket_name,
            ticket_description: ticket.ticket_description,
            category_id: ticket.category_id,
            priority_id: ticket.priority_id,
            ticket_assignment: ticket.ticket_assignment,
            ticket_status: ticket.ticket_status,
            customer_remarks: ticket.customer_remarks,
            technician_remarks: ticket.technician_remarks,
            complition_date: Moment(ticket.complition_date).format("YYYY-MM-DD"),
            user_id: ticket.user_id,
            prev_complition_date: Moment(ticket.complition_date).format("YYYY-MM-DD"),
            prev_ticket_status: ticket.ticket_status,
            prev_technician_remarks: ticket.technician_remarks,
            prev_customer_remarks: ticket.customer_remarks,
            prev_priority_id: ticket.priority_id,
            prev_ticket_description: ticket.ticket_description
        })
        console.log("**", this.state.complition_date)
        this.tog_edit_ticket();
    }

    fillTechnician = (id, ticket_assignment) => {
        this.setState({
            support_log_id: id,
            ticket_assignment: ticket_assignment,
            prev_ticket_assignment: ticket_assignment
        })
        this.tog_technician();
    }

    viewsupportCategory() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/category/support/active`, requestOptions).then(response => response.json()).then(data => {
            var array = []
            for (let i = 0; i < data.body.length; i++) {
                array.push({
                    id: data.body[i].id,
                    category_name: data.body[i].category_name,
                    category_description: data.body[i].category_description,
                    category_status: data.body[i].category_status,
                    created_at: data.body[i].created_at,
                    updated_at: data.body[i].updated_at,

                })
            }
            this.setState({ category: array })
            console.log(this.state.category)
        }).catch(error => console.log('error', error));

    }

    vieallSupportPriority() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/priority/support/active`, requestOptions).then(response => response.json()).then(data => {
            var array = []
            for (let i = 0; i < data.body.length; i++) {
                array.push({
                    id: data.body[i].id,
                    priority_name: data.body[i].priority_name,
                    priority_description: data.body[i].priority_description,
                    priority_status: data.body[i].priority_status,
                    created_at: data.body[i].created_at,
                    updated_at: data.body[i].updated_at,
                })
            }
            this.setState({ priority: array })
            console.log(this.state.priority)
        }).catch(error => console.log('error', error));
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if(e.target.value === "custom"){
            this.setState({ [e.target.name]: "custom2" ,fromDate:'',toDate:'' })
            this.tog_add()
        }
        else{
            setTimeout(() => {
                // console.log("created",this.state.created_at_filter)
                this.viewSupportLog()
            }, 1500);
        }
        console.log("cat pri", this.state.category_id, this.state.priority_id)
    }

    getTechnician() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/technicians`, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    technician: result.body
                })
            })
            .catch(error => console.log('error', error));
    }

    assignTechnician() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "ticket_assignment": this.state.ticket_assignment
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${url}/support/log/assignment/` + this.state.support_log_id, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.tog_technician()
                this.viewSupportLog()
                this.setState({
                    ticket_assignment: ''
                })
                toast(<div className='toast-message-container'>
                <h6>Ticket Management</h6>
                <p>Ticket assigned successfully.</p>
                </div>, {
                    position: "bottom-right",
                    icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'toast-success'
             })
                // console.log("abc", result)
            })
            .catch(error => console.log('error', error));
    }

    supportCount() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${url}/support/count`, requestOptions)
            .then(response => response.json())
            .then(result => {
                var completed = '';
                var newCounts = '';
                var running = '';
                var open = '';
                var index = result.body.findIndex(e => e.ticket_status === "Completed")
                console.log("indexx", index)
                if (index !== -1) {
                    completed = result.body[index].count
                } else {
                    completed = 0
                }

                index = result.body.findIndex(e => e.ticket_status === "Open")
                if (index !== -1) {
                    open = result.body[index].count
                } else {
                    open = 0
                }

                index = result.body.findIndex(e => e.ticket_status === "Running")
                if (index !== -1) {
                    running = result.body[index].count
                } else {
                    running = 0
                }

                index = result.body.findIndex(e => e.ticket_status === "New")
                if (index !== -1) {
                    newCounts = result.body[index].count
                } else {
                    newCounts = 0
                }

                this.setState({
                    completedCount: completed,
                    openCount: open,
                    newCount: newCounts,
                    runningCount: running
                })
            })
            .catch(error => console.log('error', error));
    }

    getUsers() {
        var date = this.state.created_at_filter;
        var fromDate = ''
        var toDate = ''
        if (date === "custom") {
            fromDate = Moment(this.state.fromDate).format('YYYY-MM-DD')
            toDate = Moment(this.state.toDate).format('YYYY-MM-DD')
        } else if (date !== "") {
            if(date === "1"){
                fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
                toDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            }else{
            fromDate = Moment().subtract(date, 'days').format('YYYY-MM-DD')
            toDate = Moment().format('YYYY-MM-DD')
            }
        }

        if(Moment(this.state.fromDate).format('YYYY-MM-DD') === Moment(new Date()).format('YYYY-MM-DD') && Moment(this.state.toDate).format('YYYY-MM-DD') === Moment(new Date()).format('YYYY-MM-DD')){
            return;
        }else{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
        var raw = JSON.stringify({
            "sub": this.state.id_filter,
            "email": this.state.email_filter,
            "email_verified": this.state.email_verified_filter,
            "status": this.state.status_filter,
            "fromDate": fromDate,
            "toDate": toDate,
            "Enabled": this.state.enable === "true" ? true : this.state.enable === "false" ? false : ''
        });
        console.log("Filter raw!", raw)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        var urll = `${url}/endusers/all`
        fetch(urll, requestOptions)
            .then((response) => response.json())
            .then(data => {
                var array = []
                if(this.state.modal_add_cal === true){
                    this.setState(prevState => ({
                        modal_add_cal: false
                    }));
                }
                if(data.statusCode === "200"){
                    for (let i = 0; i < data.body.length; i++) {
                        array.push({
                            sub: data.body[i].sub,
                            Username: data.body[i].Username,
                            given_name: data.body[i].given_name,
                            family_name: data.body[i].family_name,
                            email: data.body[i].email,
                            email_verified: data.body[i].email_verified,
                            UserStatus: data.body[i].UserStatus,
                            UserCreateDate: Moment(data.body[i].UserCreateDate).format('YYYY/MM/DD HH:mm:ss'),
                            UserLastModifiedDate: Moment(data.body[i].UserLastModifiedDate).format('YYYY/MM/DD HH:mm:ss'),
                           
                        })
                    }
                    this.setState({ users: array })
                }
                else(
                    this.setState({ users: [] })
                )
            })
        }
    }

    tog_add() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add
        }));
    }
    tog_add2() {
        this.setState(prevState => ({
            modal_add: !prevState.modal_add,fromDate:'',toDate:'',created_at_filter:"",DateAlert:false

        }));
        setTimeout(() => {
            this.viewSupportLog()
        }, 1500);
    }
    tog_technician() {
        this.setState(prevState => ({
            modal_technician: !prevState.modal_technician,

        }));
    }
    tog_edit_ticket() {
        this.setState(prevState => ({
            modal_edit_ticket: !prevState.modal_edit_ticket
        }));
        if (this.state.modal_edit_ticket !== false) {
            this.setState({
                ticket_name: '',
                ticket_description: '',
                category_id: '',
                priority_id: '',
                ticket_assignment: '',
                ticket_status: '',
                customer_remarks: '',
                technician_remarks: '',
                complition_date: '',
                user_id: ''
            })
        }
    }

    tog_create_ticket() {
        if (this.state.modal_create_ticket === true) {
            this.setState({
                ticket_name: '',
                ticket_description: '',
                category_id: '',
                priority_id: '',
                ticket_assignment: '',
                ticket_status: '',
                customer_remarks: '',
                technician_remarks: '',
                complition_date: '',
                user_id: ''
            })
        }
        this.setState(prevState => ({
            modal_create_ticket: !prevState.modal_create_ticket
        }));
    }

    clearFilter() {
        this.setState({
            category_id_filter: '',
            priority_id_filter: '',
            user_id_filter: '',
            ticket_id_filter: '',
            ticket_status_filter: '',
            technician_id_filter: '',
            from_date_filter: '',
            to_date_filter: '',
            created_at_filter: ''
        })
        setTimeout(() => {
            this.viewSupportLog()
        }, 100)

    }

    handleDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,fromDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else{
            this.setState({
                DateAlert: false
            }) 
            this.setState({fromDate: Moment(value).format("YYYY-MM-DD")})
        }
    }
    handleToDatechange(value){
        var dateofvisit = Moment(value, 'DD-MM-YYYY');
        var today = Moment();
        
        if(today.diff(dateofvisit, 'Hours') <= 0){
            this.setState({
                DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
            })
            
            return  
        }else if(this.state.fromDate !== ""){
            if(Moment(this.state.fromDate, 'YYYY-MM-DD').diff(dateofvisit, 'Hours') > 0){
                this.setState({
                    DateAlert: true,toDate: Moment(value).format("YYYY-MM-DD")
                })
            }else{
                this.setState({
                    DateAlert: false
                })
                this.setState({toDate: Moment(value).format("YYYY-MM-DD")})
            }
        }else{
            this.setState({
                DateAlert: false
            })
            this.setState({toDate: Moment(value).format("YYYY-MM-DD")})

        }
    }
    disableToast(){
        let toastDiv = document.querySelector('.toast-error');
        if(toastDiv == null){
            toast(<div className='toast-message-container'>
            <h6>Ticket Management</h6>
            <p>Please select a valid date.</p>
            </div>, {
                position: "bottom-right",
                icon: <i style={{marginTop:"4px",fontSize:"20px"}} className="dripicons-bell"></i>,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                className: 'toast-error'
         })
        }
    }
    render() {
        const data = {
            columns: [
                {
                    label: 'Ticket ID',
                    field: 'ticket_id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Ticket Name',
                    field: 'ticket_name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Ticket Description',
                    field: 'ticket_description',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'User Name',
                    field: 'user_id',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Status',
                    field: 'ticket_status',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Priority',
                    field: 'priority_name',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Assign Technician',
                    field: 'technician_name',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Customer Remark',
                    field: 'customer_remarks',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Technician Remark',
                    field: 'technician_remarks',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Category',
                    field: 'category_name',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Completion  Date',
                    field: 'complition_date',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Created At',
                    field: 'created_at',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Updated At',
                    field: 'updated_at',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Action',
                    field: 'button',
                    sort: "disabled",
                    width: 100
                }
            ],
            rows: this.state.tickets
        };
        
        return (
            <React.Fragment>

                {this.state.alert_confirm ? (
                    
                    <div className="modal" style={{display:"block"}} tabIndex="-1" role="dialog" id='alertBox'>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Ticket</h5>
                                
                            </div>
                            <div className="modal-body">
                                <p>{ "Are you sure you want to delete " + this.state.ticket_name + "?"}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" style={{background:"#FFFFFF0D", borderColor:"#2f2f34"}} className="btn" data-dismiss="modal"
                                onClick={() => {
                                    this.setState({
                                        alert_confirm: false,
                                    });
                                    document.getElementById('alertBox').style.display = 'none';
                                }} 
                                >Cancel</button>
                                <button type="button" className="btn"
                                onClick={() => {
                                    this.setState({ alert_confirm: false })
                                    this.deleteSupportLog();
                                    document.getElementById('alertBox').style.display = 'none';
                                }}
                                >Delete</button>
                            </div>
                            </div>
                        </div>
                    </div>

                ) : null}
                <Row>
                    <Col md='3' >
                        <Card className="mini-stat" style={{ backgroundColor: "#f5b225", height: "106px", padding: "28px", borderRadius: "1px", backgroundColor: "#303035", boxShadow: "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(0 0 0 / 25%)", marginBottom: "16px"}}>
                            <CardBody>
                                <div>

                                </div>

                                <h5>Open</h5>
                                <h5>{this.state.openCount}</h5>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='3'>
                        <Card className="mini-stat" style={{ backgroundColor: "#f5b225", height: "106px", padding: "28px", borderRadius: "1px", backgroundColor: "#303035", boxShadow: "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(0 0 0 / 25%)", marginBottom: "16px"}}>
                            <CardBody>
                                <div>

                                </div>

                                <h5>New</h5>
                                <h5>{this.state.newCount}</h5>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='3'>
                        <Card className="mini-stat" style={{ backgroundColor: "#f5b225", height: "106px", padding: "28px", borderRadius: "1px", backgroundColor: "#303035", boxShadow: "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(0 0 0 / 25%)", marginBottom: "16px"}}>
                            <CardBody>
                                <div>

                                </div>

                                <h5>Running</h5>
                                <h5>{this.state.runningCount}</h5>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md='3'>
                        <Card className="mini-stat" style={{ backgroundColor: "#f5b225", height: "106px", padding: "28px", borderRadius: "1px", backgroundColor: "#303035", boxShadow: "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(0 0 0 / 25%)", marginBottom: "16px"}}>
                            <CardBody>
                                <div>

                                </div>

                                <h5>Completed</h5>
                                <h5>{this.state.completedCount}</h5>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <div style={
                    {
                        marginTop: 20,
                        marginBottom: 30
                    }
                }>
                    <Button type="button"
                        onClick={
                            () => this.tog_create_ticket()
                        }
                        color="info"
                        className="waves-effect waves-light buttons-primary">
                        Add Ticket
                    </Button>
                </div>

                {/* Filters */}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody >
                                <Row xs="12">
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Ticket ID</Label>
                                        <Col>
                                            <Input className="form-control" name="ticket_id_filter" type="text"
                                                value={
                                                    this.state.ticket_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }
                                                id="example-search-input" />
                                        </Col>
                                    </Col>

                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Username</Label>
                                        <Col>
                                            <select className="form-control" name='user_id_filter'
                                                value={
                                                    this.state.user_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {
                                                    this.state.users.map((user) => (
                                                        <option value={user.Username}>{user.Username}</option>
                                                    ))
                                                }
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className="col-form-label">Status</Label>
                                        <Col>
                                            <select className="form-control" name='ticket_status_filter'
                                                value={
                                                    this.state.ticket_status_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="Open">Open</option>
                                                <option value="New">New</option>
                                                <option value="Running">Running</option>
                                                <option value="Completed">Completed</option>
                                                
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-tel-input" className="col-form-label">Priority</Label>
                                        <Col>
                                            <select className="form-control" name='priority_id_filter'
                                                value={
                                                    this.state.priority_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {this.state.priority.map((cat) => (
                                                    <option value={cat.id}>{cat.priority_name}</option>
                                                ))}

                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-tel-input" className="col-form-label">Technician</Label>
                                        <Col>
                                            <select className="form-control" name='technician_id_filter'
                                                value={
                                                    this.state.technician_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {this.state.technician.map((cat) => (
                                                    <option value={cat.id}>{cat.first_name}  {cat.last_name}</option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-tel-input" className="col-form-label">Category</Label>
                                        <Col>
                                            <select className="form-control" name='category_id_filter'
                                                value={
                                                    this.state.category_id_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                {this.state.category.map((cat) => (
                                                    <option value={cat.id}>{cat.category_name}</option>
                                                ))}

                                            </select>
                                        </Col>
                                    </Col>
                                    <Col>
                                        <Label for="example-password-input" className=" col-form-label">Created At</Label>
                                        <Col>
                                            <select className="form-control" name='created_at_filter'
                                                value={
                                                    this.state.created_at_filter
                                                }
                                                onChange={
                                                    this.changeHandler
                                                }>
                                                <option value="" disabled selected hidden>Select</option>
                                                <option value="0">Today</option>
                                                <option value="1">Yesterday</option>
                                                <option value="7">Last 7 days</option>
                                                <option value="30">Last 30 days</option>
                                                <option value="180">Last 6 months</option>
                                                <option value = "custom" onClick={this.tog_add}>Custom</option>
                                                <option value = "custom2" hidden>{ this.state.created_at_filter === "custom2" ? "From: " + this.state.fromDate + " To: " + this.state.toDate : "Custom"}</option>
                                                
                                            </select>
                                        </Col>
                                    </Col>
                                </Row>
                                <Col className="text-left" style={{ marginTop: 10 }}>
                                    <Button type="button"
                                        onClick={
                                            () => this.viewSupportLog()
                                        }
                                        color="warning"
                                        className="waves-effect waves-light buttons-primary">
                                        <i className='mdi mdi-filter-menu-outline'
                                            style={
                                                { marginRight: 10, fontSize: 15 }
                                            }
                                        ></i>
                                        Filter
                                    </Button>
                                    {
                                        this.state.ticket_id_filter === "" && this.state.user_id_filter === "" && this.state.ticket_status_filter === "" && this.state.priority_id_filter === "" && this.state.technician_id_filter === "" && this.state.category_id_filter === "" && this.state.created_at_filter === "" ?
                                        <Button type="button" disabled
                                            style={{ margin: 20, cursor: "default" }}
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            color="success"
                                            className="waves-effect waves-light filter-disabled">

                                            Clear Filter
                                        </Button>:
                                        <Button type="button"
                                            style={{ margin: 20 }}
                                            onClick={
                                                () => this.clearFilter()
                                            }
                                            color="success"
                                            className="waves-effect waves-light filter-buttons">

                                            Clear Filter
                                        </Button>
                                    }
                                    
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.userUpdateAlert ? <Alert color="success">
                    <strong>Update successfully</strong>
                </Alert> : null}
                {this.state.creatTicket ? <Alert color="success">
                    <strong>Ticket created successfully</strong>
                </Alert> : null}
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <MDBDataTable
                                    responsive
                                    bordered
                                    striped
                                    data={data}

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_technician
                    }
                        toggle={
                            this.tog_technician
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader >
                            Assign Technician
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup row className="formContainer">
                                <Label for="example-password-input" className="col-form-label ml-3">Assign Ticket To</Label>
                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='ticket_assignment'
                                        value={
                                            this.state.ticket_assignment
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                            <option value=""  selected hidden>Select</option>
                                        {this.state.technician.map((cat) => (
                                            <option value={cat.id}>{cat.first_name}  {cat.last_name}</option>
                                        ))}
                                    </select>
                                </Col>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_technician
                                }>Close
                            </Button>
                            {
                                this.state.ticket_assignment === this.state.prev_ticket_assignment ?
                                    <Button type="button" color="primary"
                                        onClick={
                                            () => this.assignTechnician()
                                        }
                                        style={{cursor: "default"}}
                                        disabled
                                        className="waves-effect waves-light">Save Changes
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.assignTechnician()
                                        }
                                        className="waves-effect waves-light">Save Changes</Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>

                {/* Create new ticket */}
                <Row>
                    <Modal isOpen={
                        this.state.modal_create_ticket
                    }
                        toggle={
                            this.tog_create_ticket
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader>
                            Add Details
                        </ModalHeader>
                        <ModalBody>
                            <AvForm className="formContainer">
                                <AvField
                                    name="ticket_name"
                                    label="Ticket Name *"
                                    className="w-100"
                                    placeholder="Enter Ticket Name"
                                    type="text"
                                    errorMessage="Enter Ticket Name"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.ticket_name
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                                <AvField
                                    name="ticket_description"
                                    label="Ticket Description *"
                                    className="w-100"
                                    placeholder="Enter Ticket Description"
                                    type="text"
                                    errorMessage="Enter Ticket Description"
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.ticket_description
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />

                            </AvForm>

                            {this.state.userAlert ? <Alert color="danger">
                                <strong>Please select one user</strong>
                            </Alert> : null}
                            <Label for="example-password-input" className="col-form-label">Username *</Label>
                            <FormGroup row className="formContainer">

                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='user_id'
                                        value={
                                            this.state.user_id
                                        }
                                        required
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        {
                                            this.state.users.map((user) => (
                                                <option value={user.Username}>{user.Username}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                            </FormGroup>
                            {this.state.categoryAlert ? <Alert color="danger">
                                <strong>Please select one category</strong>
                            </Alert> : null}
                            <Label for="example-password-input" className="col-form-label">Category *</Label>
                            <FormGroup row className="formContainer">

                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='category_id'
                                        value={
                                            this.state.category_id
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        {this.state.category.map((cat) => (
                                            <option value={cat.id}>{cat.category_name}</option>
                                        ))}

                                    </select>
                                </Col>
                            </FormGroup>
                            {this.state.priorityAlert ? <Alert color="danger">
                                <strong>Please select one priority</strong>
                            </Alert> : null}
                            <Label for="example-password-input" className="col-form-label">Priority *</Label>
                            <FormGroup row className="formContainer">

                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='priority_id'
                                        value={
                                            this.state.priority_id
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        {this.state.priority.map((cat) => (
                                            <option value={cat.id}>{cat.priority_name}</option>
                                        ))}

                                    </select>
                                </Col>
                            </FormGroup>
                            {this.state.completeAlert ? <Alert color="danger">
                                <strong>Please select valid date</strong>
                            </Alert> : null}
                            <AvForm className="formContainer">
                            <AvField
                                    name="complition_date"
                                    label="Completion Date *"
                                    className="w-100"
                                    placeholder="Enter Completion Date"
                                    type="date"
                                    errorMessage="Enter Completion Date or Valid Date"
                                    min = {this.state.todayDate}
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.complition_date
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                            </AvForm>
                            <Label for="example-tel-input" className="col-form-label">Customer Remark</Label>
                            <FormGroup row className="formContainer">

                                <Col sm="12">
                                    <Input className="w-100 mb-0" name="customer_remarks" type="text"
                                        value={
                                            this.state.customer_remarks
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <Label for="example-tel-input" className="col-form-label">Technician Remark</Label>
                            <FormGroup row className="formContainer">

                                <Col sm="12">
                                    <Input className="w-100 mb-0" name="technician_remarks" type="text"
                                        value={
                                            this.state.technician_remarks
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            {this.state.assignmentAlert ? <Alert color="danger">
                                <strong>Please select one support engineer</strong>
                            </Alert> : null}
                            <Label for="example-password-input" className="col-form-label">Assign Ticket To *</Label>
                            <FormGroup row className="formContainer">

                                <Col sm="12">
                                    <select className="selectFormContainer mb-0 w-100" name='ticket_assignment'
                                        value={
                                            this.state.ticket_assignment
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        {this.state.technician.map((cat) => (
                                            <option value={cat.id}>{cat.first_name}  {cat.last_name}</option>
                                        ))}
                                    </select>
                                </Col>
                            </FormGroup>
                            {this.state.statusAlert ? <Alert color="danger">
                                <strong>Please select ticket status</strong>
                            </Alert> : null}
                            
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_create_ticket
                                }>Close
                            </Button>
                            {
                                this.state.ticket_name === "" || this.state.ticket_description === "" || this.state.user_id === "" || this.state.category_id === "" || this.state.priority_id === "" || this.state.ticket_assignment === ""  ?
                                    <Button type="button" color="primary"
                                        onClick={
                                            () => this.createSupportLog()
                                        }
                                        style={{cursor: "default"}}
                                        disabled
                                        className="waves-effect waves-light">Add Ticket
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.createSupportLog()
                                        }
                                        className="waves-effect waves-light">Add Ticket</Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>

                {/* Update ticket */}
                <Row>
                    <Modal isOpen={
                        this.state.modal_edit_ticket
                    }
                        toggle={
                            this.tog_edit_ticket
                        }
                        autoFocus={true}
                        size="md"
                        className="modal-dialog-centered">
                        <ModalHeader >
                            Edit Details
                        </ModalHeader>
                        <ModalBody>
                            {this.state.updateFailed ? <Alert color="danger">
                            <strong>{this.state.updateFailedMasg}</strong>
                            </Alert> : null}

                            <Label for="example-tel-input" className=" col-form-label">Ticket Name</Label>
                            <FormGroup row className="formContainer">
                               
                                <Col >
                                    <Input className="w-100 mb-0" name="ticket_name" type="text" style={{color: "#6c757d"}}
                                        value={
                                            this.state.ticket_name
                                        }
                                        disabled
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <Label for="example-tel-input" className="col-form-label">Ticket Description</Label>
                            <FormGroup row className="formContainer">
                               
                                <Col >
                                    <Input className="mb-0 w-100" name="ticket_description" type="text"
                                        value={
                                            this.state.ticket_description
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <Label for="example-password-input" className="col-form-label">Username</Label>
                            <FormGroup row className="formContainer">
                               
                                <Col >
                                    <select className="selectFormContainer mb-0 w-100" name='user_id'
                                        value={
                                            this.state.user_id
                                        }
                                        disabled
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Select</option>
                                        {
                                            this.state.users.map((user) => (
                                                <option value={user.Username}>{user.Username}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                            </FormGroup>
                            <Label for="example-password-input" className=" col-form-label">Category</Label>
                            <FormGroup row className="formContainer">
                                
                                <Col >
                                    <select className="selectFormContainer mb-0 w-100" name='category_id'
                                        value={
                                            this.state.category_id
                                        }
                                        disabled
                                        onChange={
                                            this.changeHandler
                                        }>
                                        {this.state.category.map((cat) => (
                                            <option value={cat.id}>{cat.category_name}</option>
                                        ))}

                                    </select>
                                </Col>
                            </FormGroup>
                            <Label for="example-password-input" className=" col-form-label">Priority</Label>
                            <FormGroup row className="formContainer">
                               
                                <Col >
                                    <select className="selectFormContainer mb-0 w-100" name='priority_id'

                                        onChange={
                                            this.changeHandler
                                        }>
                                        {this.state.priority.map((cat) => (
                                            this.state.priority_id === cat.id ? <option selected value={cat.id}>
                                                {cat.priority_name} </option> :
                                                <option value={cat.id}>{cat.priority_name}</option>
                                        ))}

                                    </select>
                                </Col>
                            </FormGroup>
                            <Label for="example-tel-input" className="col-form-label">Customer Remark</Label>
                            <FormGroup row className="formContainer">
                                
                                <Col >
                                    <Input className="mb-0 w-100" name="customer_remarks" type="text"
                                        value={
                                            this.state.customer_remarks
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            <Label for="example-tel-input" className="col-form-label">Technician Remark</Label>
                            <FormGroup row className="formContainer">
                                
                                <Col >
                                    <Input className="mb-0 w-100" name="technician_remarks" type="text"
                                        value={
                                            this.state.technician_remarks
                                        }
                                        onChange={
                                            this.changeHandler
                                        }
                                        id="example-search-input" />
                                </Col>
                            </FormGroup>
                            {this.state.completeAlert ? <Alert color="danger">
                                <strong>Please enter valid date.</strong>
                            </Alert> : null}
                            <AvForm className="formContainer">
                                <AvField
                                    name="complition_date"
                                    className="w-100"
                                    label="Completion Date *"
                                    placeholder="Enter Completion Date"
                                    type="date"
                                    errorMessage="Enter Completion Date or Valid Date"
                                    min = {this.state.todayDate}
                                    validate={{ required: { value: true } }}
                                    value={
                                        this.state.complition_date
                                    }
                                    onChange
                                    ={this.changeHandler}
                                />
                           </AvForm>
                            <Label for="example-password-input" className="col-form-label">Ticket Status</Label>
                            <FormGroup row className="formContainer">
                                
                                <Col >
                                    <select className="selectFormContainer mb-0 w-100" name='ticket_status'
                                        value={
                                            this.state.ticket_status
                                        }
                                        onChange={
                                            this.changeHandler
                                        }>
                                        <option value="" disabled selected hidden>Status</option>
                                        <option value="Open">Open</option>
                                        <option value="New">New</option>
                                        <option value="Running">Running</option>
                                        <option value="Completed">Completed</option>
                                    </select>
                                </Col>
                            </FormGroup>
                           
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" className="waves-effect form-cancel-buttons"
                                onClick={
                                    this.tog_edit_ticket
                                }>Close
                            </Button>
                            {
                                this.state.ticket_description === this.state.prev_ticket_description && this.state.priority_id === this.state.prev_priority_id && this.state.customer_remarks === this.state.prev_customer_remarks && this.state.technician_remarks === this.state.prev_technician_remarks && this.state.ticket_status === this.state.prev_ticket_status && this.state.complition_date === this.state.prev_complition_date ?
                                    <Button type="button" color="primary"
                                        onClick={
                                            () => this.updateSupportLog()
                                        }
                                        style={{cursor: "default"}}
                                        disabled
                                        className="waves-effect waves-light">Save Changes
                                    </Button>
                                    : <Button type="button" color="primary"
                                        onClick={
                                            () => this.updateSupportLog()
                                        }
                                        className="waves-effect waves-light">Save Changes
                                    </Button>
                            }

                        </ModalFooter>
                    </Modal>
                </Row>

                <Row>
                    <Modal isOpen={
                        this.state.modal_add
                    }
                        toggle={
                            this.tog_add
                        }
                        autoFocus={true}
                        size="lg">
                        <ModalHeader toggle={
                            this.tog_add2
                        }>
                            Select Date
                        </ModalHeader>
                        <ModalBody>
                        {this.state.DateAlert ?  
                        this.disableToast()
                        : Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                        this.disableToast()
                          : null}
                            <Row>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleDatechange} 
                                    />
                                </Col>
                                <Col>
                                    <Calendar 
                                        onChange={this.handleToDatechange} 
                                    />
                                </Col>
                            </Row>                                                   
                        </ModalBody>
                        <ModalFooter>
                        {
                                 this.state.DateAlert === true || this.state.fromDate === '' || this.state.toDate === ''? 
                                 <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewSupportLog
                                    }>Save
                                </Button> 
                                : 
                                Moment(this.state.fromDate).isAfter(Moment(this.state.toDate)) || Moment(this.state.fromDate).isAfter(Moment()) || Moment(this.state.toDate).isAfter(Moment())?
                                <Button type="button" color="primary" className="waves-effect" disabled
                                    onClick={
                                        this.viewSupportLog
                                    }>Save
                                </Button> :
                                <Button type="button" color="primary" className="waves-effect"
                                    onClick={
                                        this.viewSupportLog
                                    }>{
                                        this.state.isLoadingSave === true ? 
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="10"
                                                    visible={this.state.isLoadingSave}
                                                />
                                            </div>
                                        : "Save"
                                    }
                                </Button>
                            }
                            
                        </ModalFooter>
                    </Modal>
                </Row>
                <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />

            </React.Fragment>
        );
    }
}

export default connect(null, { setBreadcrumbItems })(CustomerService);